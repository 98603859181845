:root {
    /*
        To add new:
            0. you need to add 3 variable
            1. name the variable without theme identifier
                and set the value to the default value using var
            2. create a default variation. Add -default to the name
            3. create a dark variation. Add -dark to the name
            4. Add the first variable name to DynamicThemeUtil.ts valueCssClasses
    */
    --ct-color-bg: var(--ct-color-bg-default);
    --ct-color-bg-inverse: var(--ct-color-bg-inverse-default);
    --ct-color-text: var(--ct-color-text-default);
    --ct-color-text-inverse: var(--ct-color-text-inverse-default);
    --ct-color-text-primary: var(--ct-color-text-primary-default);
    --ct-color-list-border: var(--ct-color-list-border-default);

    /* DEFAULT COLORS */
    --ct-color-bg-default: white;
    --ct-color-bg-inverse-default: black;
    --ct-color-text-default: black;
    --ct-color-text-inverse-default: white;
    --ct-color-text-primary-default: blue;
    --ct-color-list-border-default: black;

    /* DARK COLORS */
    --ct-color-bg-dark: #1f1f1f;
    --ct-color-bg-inverse-dark: white;
    --ct-color-text-dark: white;
    --ct-color-text-inverse-dark: black;
    --ct-color-text-primary-dark: yellow;
    --ct-color-list-border-dark: white;

    /* SHARED COLORS */
    --ct-color-red: #cf2a27;
}

.ct-container {
    margin: 0;
    padding: 0;
}

.ct-theme {
    background-color: var(--ct-color-bg);
    color: var(--ct-color-text);
}

/*.ct-dark-theme {
    color: var(--color-text-dark);
    background-color: var(--color-bg-dark);
}*/

.ct-cancel-button {
    background-color: var(--ct-color-red);
}

.ct-link-button {
    color: rgb(16, 110, 190);
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-decoration: underline;
}