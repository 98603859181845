.ct-dn-warning {
    color: var(--ct-color-red);
    font-size: 14pt;
    font-weight: bold;
}

.ct-dn-required-star::after {
    content: "*";
    color: rgb(164, 38, 44);
}

.ct-top-31px {
    top: 31px;
}

@media (max-width: 576px) {
    .ct-dn-list-item-height {
        min-height: 185px;
    }
}

@media (min-width: 577px) {
    .ct-dn-list-item-height {
        min-height: 100px;
    }
}

.ct-dn-min-height-125 {
    min-height: 125px;
}