.ct-microphone-recorder-duration-hours,
.ct-microphone-recorder-duration-mins,
.ct-microphone-recorder-duration-secs,
.ct-microphone-recorder-duration-divider {
  font-size: 20pt;
}

.ct-microphone-recorder-duration-milliseconds {
  font-size: 12pt;
}

.ct-microphone-recorder-duration-divider {
}
